import { ServiceTopology } from '@wix/viewer-manager-interface'
import { IComponentsRegistrar } from '@wix/thunderbolt-components-loader'

import {
	registry,
	createRegistryAPI,
	getComponentsLibraries,
	IThunderboltRegistryAPI,
} from '@wix/editor-elements-registry/thunderbolt'
import { ComponentsRegistryError, ComponentsRegistryErrorTypes } from './errors'
import { getGlobalRegistryRuntime } from './runtime'
import { splitComponentName } from './common'

type IComponentsRegistryAPI = IThunderboltRegistryAPI

export { IComponentsRegistryAPI }

/**
 * Components Registry for Thunderbolt Client Side Rendering
 */
export interface IComponentsRegistryCSR {
	getRegistryAPI: () => IComponentsRegistryAPI
	/**
	 * Legacy API is used for migrating to the thunderbolt + registry integration
	 */
	getLegacyComponentsRegistrarAPI: () => IComponentsRegistrar
}

export async function createComponentsRegistryCSR(
	options: {
		serviceTopology?: ServiceTopology
		url?: string
	} = {}
): Promise<IComponentsRegistryCSR> {
	const { serviceTopology, url } = options

	const runtime = getGlobalRegistryRuntime()

	if (!runtime) {
		if (!serviceTopology) {
			throw new ComponentsRegistryError(
				'"serviceTopology" or "runtime" is required to create registry',
				ComponentsRegistryErrorTypes.INVALID_ARGUMENTS
			)
		}
	}

	const registryAPI = await createRegistryAPI(registry, {
		shouldLoadCSSAssets: true,
		options: {
			useScriptsInsteadOfEval: true,
		},
		mode: 'lazy',
		...(runtime ? runtime : { libraries: getComponentsLibraries(serviceTopology as any, url) }),
	})

	return {
		getLegacyComponentsRegistrarAPI() {
			return {
				registerComponents: (hostAPI) => {
					registryAPI.forEachComponent((componentName, loader) => {
						/**
						 * TODO: hardcoded split should be removed after "specs.thunderbolt.componentsRegistry" experiment is merged
						 */
						const [name, uiType] = splitComponentName(componentName)

						hostAPI.registerComponent(
							name,
							async () => {
								const model = await loader()

								return {
									default: model.component,
									component: model.component,
									controller: model.controller,
								}
							},
							uiType
						)
					})
				},
			}
		},

		getRegistryAPI() {
			return registryAPI
		},
	}
}
