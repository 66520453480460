import { IRegistryRuntime } from '@wix/editor-elements-registry'

export const REGISTRY_RUNTIME_GLOBAL_PROP_NAME = '_registry_runtime'

declare global {
	interface Window {
		[REGISTRY_RUNTIME_GLOBAL_PROP_NAME]: IRegistryRuntime
	}
}

export function getGlobalRegistryRuntime(): IRegistryRuntime | null {
	if (process.env.browser) {
		return window[REGISTRY_RUNTIME_GLOBAL_PROP_NAME]
	}

	return null
}
