import { isSafeUrl } from '@wix/thunderbolt-commons'
import { IRegistryAPI } from '@wix/editor-elements-registry'

type AnyComponentRegistryAPI = IRegistryAPI<any>

// TODO - move more methods to this common place
export interface IComponentsCommonRegistry {
	getStylableMetadataURLs: () => Array<string>
}

export const splitComponentName = (componentName: string) => componentName.split('_')
export const getComponentType = (componentName: string) => splitComponentName(componentName)[0]
const shouldPreserveFullUrl = (url: string) => isSafeUrl(new URL(url))

export const createCommonRegistryMethods = <TComponentsRegistry extends AnyComponentRegistryAPI>(
	registryAPI: TComponentsRegistry
): IComponentsCommonRegistry => {
	return {
		getStylableMetadataURLs() {
			return registryAPI
				.getLibrariesCustomAssets()
				.filter((asset) => asset.type === 'stylable-metadata')
				.map(({ url }) => {
					/**
					 * NOTE: logic was duplicated form tbElementsBuilder
					 */
					if (shouldPreserveFullUrl(url)) {
						return url
					}

					const [, stylableHash] = url.match(/([a-zA-Z0-9.-]+)\.metadata\.json/) || []
					return stylableHash ? stylableHash : ''
				})
				.filter((url) => !!url)
		},
	}
}
